import React from "react";
import { useViewport } from "../../../shared/hooks";
import { imageParser } from "../../../shared/utils/image.utils";
import styled from "@emotion/styled";

function TrustedBy() {
  const below600 = useViewport(600).below;

  const CompaniesWrapper = styled.div`
    padding: 2rem 0;
    white-space: nowrap;

    .companies-logos {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @keyframes slide {
      from {
        transform: translate(0);
      }
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }

    @media (max-width: 600px) {
      .companies-logos {
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
        padding: 0;
        width: fit-content;
        animation: slide 20s linear infinite;
      }
      .companies-logos img {
        padding: 0 10px;
        width: 136px;
        height: 40px;
      }
    }
  `;

  return (
    <>
      <CompaniesWrapper className="logos-container">
        <div className="companies-logos">
          <img
            src={imageParser("trusted_by_section/kokilaben.svg")}
            alt="kokilaben"
          />
          <img
            src={imageParser("trusted_by_section/shadi.svg")}
            alt="shadow fax"
          />
          <img
            src={imageParser("trusted_by_section/bytelearn.svg")}
            alt="shadow fax"
          />
          <img
            src={imageParser("trusted_by_section/rebel.svg")}
            alt="shadow fax"
          />
          <img
            src={imageParser("trusted_by_section/shadow_fax.svg")}
            alt="shadow fax"
          />
          <img
            src={imageParser("trusted_by_section/foodland.svg")}
            alt="foodland"
          />
          {below600 && (
            <>
              <img
                src={imageParser("trusted_by_section/kokilaben.svg")}
                alt="kokilaben"
              />
              <img
                src={imageParser("trusted_by_section/shadi.svg")}
                alt="shadow fax"
              />
              <img
                src={imageParser("trusted_by_section/bytelearn.svg")}
                alt="shadow fax"
              />
              <img
                src={imageParser("trusted_by_section/rebel.svg")}
                alt="shadow fax"
              />
              <img
                src={imageParser("trusted_by_section/shadow_fax.svg")}
                alt="shadow fax"
              />
              <img
                src={imageParser("trusted_by_section/foodland.svg")}
                alt="foodland"
              />
            </>
          )}
        </div>
      </CompaniesWrapper>
    </>
  );
}

export default React.memo(TrustedBy);
