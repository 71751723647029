import { Grid } from "@mui/material";
import styled from "@emotion/styled";

const InvestorStylesWrapper = styled(Grid)`
  padding: 0 5vw;
  max-width: 1536px;
  margin: 56px auto 0;

  @media (max-width: 600px) {
    padding: 0;
  }

  .sliderCls {
    margin-left: auto;
    margin-right: auto;
    // width: 96%;
    text-align: center;

    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
      }
    }

    @media (min-width: 600) and (max-width: 850) {
      width: 70%;
      margin-left: 13%;
    }

    .slick-dots li button:before {
      opacity: 1;
      content: url("/images/Ellipse 22.svg");
    }

    .slick-dots li.slick-active button:before {
      color: #fa6f55;
      opacity: 1;
      content: url("/images/Ellipse 20.svg");
    }

    .slick-dots li.slick-active a svg path {
      fill-opacity: 1;
    }

    .slick-dots li button {
      color: #fff;
    }

    .slick-next {
      right: -30px;
      height: auto;
      width: 16px;
    }

    .slick-prev {
      left: -30px;
      height: auto;
      width: 16px;
    }

    .feedback_text_1 {
      font-style: normal;
      line-height: 15px;
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.5px;
    }

    .imgContent {
      opacity: 0.7;

      @media (min-width: 600px) {
        padding-left: 2em;
      }

      @media (max-width: 600px) {
        padding: 0em 1em;
      }
    }
    .opt {
      opacity: 0.5 !important;

      &:hover {
        opacity: 1.5 !important;
      }
    }

    .imgSize {
      width: 10em !important;

      @media (min-width: 800px) {
        width: 14em !important;
      }
    }
  }

  .sliderClsOne {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;

    @media (min-width: 850px) {
      width: 94%;
      margin-left: 3.5%;
    }

    @media (min-width: 600px) and (max-width: 850px) {
      width: 70%;
      margin-left: 13%;
    }

    .slick-dots li button:before {
      opacity: 1;
      content: url("/images/On.svg");
    }

    .slick-dots li.slick-active button:before {
      color: #fa6f55;
      opacity: 1;
      content: url("/images/Off.svg");
    }

    .slick-dots li.slick-active a svg path {
      fill-opacity: 1;
    }

    .slick-dots li button {
      color: #fff;
    }

    .slick-next {
      right: -30px;
      height: auto;
      width: 16px;
    }

    .slick-prev {
      left: -30px;
      height: auto;
      width: 16px;
    }

    .feedback_text_1 {
      font-style: normal;
      line-height: 15px;
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.5px;
    }

    .imgContent {
      opacity: 0.7;
      width: 90% !important;
      @media (min-width: 600px) {
        padding-left: 2em;
      }

      @media (max-width: 600px) {
        padding: 0em 1em;
      }
    }

    .opt {
      &:hover {
        opacity: 1.5 !important;
      }
    }
  }

  .headerTitle {
    color: var(--kenkoDeepTeal);
    margin-bottom: 4em;
    @media (max-width: 600px) {
      margin-bottom: 2em;
    }

    .heading {
      @media (max-width: 599.95) {
        font-size: ${(freeHealth: any) => (freeHealth ? "30px" : "2.125rem")};
        font-weight: 900;
      }

      @media (min-width: 600px) {
        margin: auto;
        display: flex;
        justify-content: center;
      }

      font-size: ${(props: any) => (props.freeHealth ? "47px" : "35px")};
      font-weight: 700;
      font-family: Merriweather;
    }
  }
`;

import { kenkoDeepTeal } from "../../../shared/assets/colors";

export const trustedBySection = {
  color: kenkoDeepTeal,
  backgroundColor: "#F6F6F6",
  position: "relative",
};

export default InvestorStylesWrapper;
